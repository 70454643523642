import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import Bride from '@assets/images/p-Jobins.jpg';
import Groom from '@assets/images/p-Anju.jpg';

import { styWrapper } from './styles';

function HelloSection({ isInvitation }) {
  const finalSubtitle = isInvitation ? '17 June 2024, St. Josephs Church, Thidanad' : 'Monday, 17 June 2024';

  return (
    <Fragment>
      <div id="fh5co-couple" css={styWrapper}>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <h2 className="main-font">Together with Christ, we are as one</h2>
              <h3 className="sub-title hs">{finalSubtitle}</h3>
              <p className="info">
              With the grace of God and the intention to build a home rooted in love and compassion, 
              we seek your prayers for our marriage to be blessed with harmony and abundance.
              </p>
            </div>
          </div>
          <div className="couple-wrap">
            <div className="couple-half">
              <div className="groom">
                <img src={Bride} alt="groom" className="img-responsive" />
              </div>
              <div className="desc-groom">
                <h3 className="main-font">Jobins Tom</h3>
                <p className="parent-name parent-name__top">
                Son of Tomichan K. V & Marykutty Tomy,<br /> Kizhakevayalil
                </p>
              </div>
            </div>
            <p className="heart text-center">
              <i className="icon-heart2"></i>
            </p>
            <div className="and-love">
              <i>&</i>
            </div>
            <div className="couple-half">
              <div className="bride">
                <img src={Groom} alt="groom" className="img-responsive" />
              </div>
              <div className="desc-bride">
                <h3 className="main-font">Anju Jacob</h3>
                <p className="parent-name">
                  Daughter of Jacob Sunny & Geena Sunny,<br /> Kuttenchalil
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isInvitation ? (
        <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Regarding Families</h2>
                <p className="info">
                  Meet the family of the groom, Jobins Tom, son of Tomichan and Marykutty Tomy. 
                  Jobins is the eldest one, and his brother Abin Tom is currently pursuing his higher studies. 
                  The family is known for their warmth and hospitality, always welcoming 
                  others with open arms..
                </p>
                <p className="info">
                  On the bride's side, we have Anju Jacob, daughter of Sunny and Geena Sunny. 
                  Anju has a close-knit family, including her elder sister Chinju, her husband Rooble, and their 
                  adorable children, Ritham and Amylyn..
                </p>
                <p>
                  Together, Jobins and Anju bring together two families with deep-rooted traditions 
                  and a shared commitment to love and support. As they embark on this new journey 
                  together, they carry with them the love and blessings of their families, who are 
                  overjoyed to see them unite in marriage."
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}

HelloSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default HelloSection;
