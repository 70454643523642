import ImgKembaliBerjumpa from '@assets/images/p-kembali-berjumpa.jpg';
import ImgJakartaKroasia from '@assets/images/p-jakarta-kroasia.jpg';
import ImgBoarding from '@assets/images/p-boarding-smg.jpg';
import ImgKembaliKeIndonesia from '@assets/images/p-kembali-ke-indonesia-min-resize.jpg';
import ImgNdodokLawang from '@assets/images/p-ndodok-lawang.jpg';

export const stories = [
  {
    title: 'About Jobins ',
    date: '22 October 1994',
    description:
      "Jobins, born on October 22, 1994, at Bhranaganam Maygiri Hospital, is a testament to resilience and academic skills. His journey began at Chemmalamattom School, where he honed his intellect before pursuing a Bachelor's in Mathematics from St. Thomas College, Pala, followed by a Master's in Computer Applications from Marian College, Kuttikanam.  With nearly five years in the IT industry, Jobins has cultivated a reputation as a diligent professional. Presently, he serves as a consultant at Applexus Trivandrum, where his expertise shines brightly. Beyond his work and career, Jobins finds solace and joy in his hobbies, which include exploring diverse cuisines, continuous learning, and enjoy himself in watching series and movies. However, what truly distinguishes Jobins is his calm and positive nature.Known for his tranquil presence, he radiates happiness and extends a helping hand to those in need, embodying the essence of kindness and generosity.",
    image: ImgNdodokLawang,
  },
  {
    title: 'About Anju',
    date: '10 November 1996',
    description:
      "Anju, born on November 10, 1996, at Sahurdaya Hospital in Alappuzha, has a vibrant personality and a passion for life. Growing up in Alappuzha, she completed her schooling locally before pursuing a Bachelor's in Computer Applications from BVM College, Cherpunkal, and a Master's in Computer Applications from Saintgits College. With four years of experience in the IT industry, Anju currently thrives as an associate consultant at Applexus Trivandrum. Outside of her professional life, she finds joy in various activities such as reading, cooking, dancing, watching movies, and writing. Anju is known for her infectious smile and her emotional depth. She is naturally friendly and talkative with her close friends and possesses a kind and caring heart.",
    image: ImgKembaliBerjumpa,
  },
  {
    title: 'Our Story Begins...',
    // date: 'Oktober 2017 - 2018',
    description:
      "Amidst the daily hustle of corporate life, Jobins, yearning for companionship to ease the burdens of work, career, and the solitary ache of loneliness. His quest led him to the realm of matrimony, where fate had a delightful surprise awaiting him.Enter Anju, who breezed into Jobins' office as a trainee consultant, igniting a spark that would illuminate their lives. Jobins found himself drawn to her magnetic personality, her emotional depth, and her fondness for wearing her heart on her sleeve. Anju, with her adorable vulnerability, touched deep in Jobins' heart, creating an empathy he didn't know he possessed. Anju, ever the chatterbox, filled the air with her laughter and chatter, a delightful contrast to Jobins' quiet nature.  In her company, Jobins found solace, comfort, and the beginnings of a heartfelt connection.",
      image: ImgJakartaKroasia,
  },
  {
    title: 'Infinite Affection',
    // date: '2018 - 2019',
    description:
      "As days turned into weeks, Yet Jobins admiration for her was rooted in a deep respect for her essence, her values, and the warmth she brought into his life.Meanwhile, Anju too was traversing the winding paths of matrimony, seeking a partner who would complement her spirit and stand by her through life's twists and turns. Initially viewing Jobins as nothing more than a friendly colleague, Anju's perspective gradually shifted as she peeled back the layers of his personality, discovering a similar spirit whose dignity shone brightly in their interactions. In the quiet moments of interactions, Anju found herself drawn to Jobins' unwavering support, his gentle behaviour, and the sincerity with which he approached every aspect of life. And so, guided by the gentle whispers of destiny, Jobins raised the courage to express his feelings to Anju. To his joy and relief, Anju's response was one of genuine affection, a blossoming realization that the love she had been seeking might have been standing beside her all along.",
    image: ImgBoarding,
  },
  {
    title: 'A Love Eternal',
    // date: '3 Maret 2019',
    description:
      "With hearts entwined and spirits soaring, Jobins and Anju embarked on a journey of love, trust, and mutual understanding. Their decision to approach their families marked the beginning of a new chapter, one filled with the promise of shared dreams, whispered confessions, and a love that would weather the storms of life. In each other's arms, they found the sanctuary they had longed for, a haven where their souls could dance to the rhythm of love's eternal melody. And as they stood hand in hand, gazing into the horizon of their shared future, Jobins and Anju knew that their love story was not just beautiful; it was a testament to the transformative power of love in all its myriad hues.",
    image: ImgKembaliKeIndonesia,
  },
  // {
  //   title: 'Ndodok Lawang',
  //   date: '31 Agustus 2019',
  //   description:
  //     'Akhirnya tiba di masa Anju dan keluarga hadir ke rumah Jobins untuk menyampaikan niat baik. Mulai dari acara Ndodok Lawang ini, dilanjutkan dengan persiapan pernikahan yang insha Allah akan diselenggarakan pada tanggal 3 Oktober 2020.',
  //   image: ImgNdodokLawang,
  // },
];
