import React, { Fragment } from 'react';
import { bool } from 'prop-types';
import { styWrapper } from '../HelloSection/styles';

function FooterSection({ isInvitation }) {
  return (
    <Fragment>
      {!isInvitation && (
        <div id="fh5co-couple" className="fh5co-section-gray" css={styWrapper}>
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
                <h2 className="main-font">Thank You</h2>
                <p className="info">
                    We are thrilled to invite you to join us in celebrating our upcoming marriage 
                    ceremony. Your presence would mean the world to us as we exchange vows and embark
                     on this beautiful journey together.
                </p>
                <p className="info">
                  For all your prayers and blessings,
                  <br /> we thank you. <br />
                  We ask that you continue to keep us in your prayers as we begin this new chapter of our lives together.
                  We look forward to sharing this special day with you!.
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <footer id="fh5co-footer" role="contentinfo">
        <div className="container">
          <div className="row copyright">
            <div className="col-md-12 text-center">
              <p>
                <small className="block">&copy; 2024 Jobins & Anju Wedding. All Rights Reserved.</small>
                {/* <small className="block">
                  Covid-19 Icon by{' '}
                  <a href="https://www.flaticon.com/packs/covid-protection-measures-5" target="_blank" rel="noreferrer">
                    Flat Icon - Frepik
                  </a>
                </small> */}
                <small className="block">
                  Song by{' '}
                  <a href="https://www.youtube.com/watch?v=ilRbNgfyLzo" target="_blank" rel="noreferrer">
                  PEARLISH | Love Theme BGM
                  </a>
                </small>
                {/* <small className="block">
                  Original Template from{' '}
                  <a href="http://freehtml5.co/" target="_blank" rel="noreferrer">
                    FREEHTML5.co
                  </a>
                </small> */}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}

FooterSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(FooterSection);
