// import Nita from '@assets/images/w-nita.jpg';
import Haseena from '@assets/images/w-haseena.jpeg';
import Nalanda from '@assets/images/w-nalanda-1.jpeg'
import ihsan from './assets/w-ihsan.jpg';


export const wishlist = [
  {
    name: 'Haseena Thouseef',
    infoName: 'Anju\'s Friend',
    description: `Dear Anju and Jobins, I'm absolutely thrilled for you both on your wedding day! It's wonderful to see you two come together. Remember to always take care of each other and nurture your bond. Here's to a lifetime of happiness and love.`,
    image: Haseena,
  },
  {
    name: 'Nalanda S',
    infoName: 'Anju\'s Friend',
    description: `As I stand by your side today, I can't help but reflect on the incredible journey that has brought you and Jobins chettan to this beautiful moment. I've witnessed your love story unfold, from the little fights that made you stronger to the countless moments of joy that brought you closer.

    Your unwavering support and friendship have meant the world to me, and it fills my heart with immense happiness to see you marry the love of your life. May your marriage be filled with endless laughter, love, and unforgettable memories.
    
    Congratulations, my dear friend! Here’s to a lifetime of happiness together.`,
    image: Nalanda,
  }
];
