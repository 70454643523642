import React, { Fragment } from 'react';
import { bool } from 'prop-types';

import WeddingInfoBox from './WeddingInfoBox';
// import ButtonLive from './ButtonLive';
import { styWrapper } from './styles';

function WeddingSection({ isInvitation }) {
  const renderGuestInfo = () => {
    return (
      <Fragment>
        <div className="col-md-8 col-md-offset-4">
          <WeddingInfoBox title="MARRIAGE CEREMONY" time="11.00 AM" date="Monday, 17 June 2024" />
        </div>
        {/* <ButtonLive /> */}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div id="fh5co-event" css={styWrapper}>
        <div className="overlay" />
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-md-offset-2 text-center fh5co-heading">
              <span className="bismillah">IN THE NAME OF GOD, THE MOST GRACIOUS, THE MOST MERCIFUL</span><br/>
              <h2 className="main-font main-font__wedding">Marriage Ceremony and Wedding Celebrations</h2>
              <span className="sub-title sub-title__wedding">GOD WILLING IT WILL BE HELD ON:</span>
            </div>
          </div>
          <div className="row">
            {!isInvitation && renderGuestInfo()}
            {isInvitation && (
              <div className="col-md-10 col-md-offset-1">
                <WeddingInfoBox
                  title="MARRIAGE CEREMONY"
                  time="Akan dilaksanakan pagi hari "
                  date="Monday, 17 June 2024"
                  description="Orange Ballroom <br/>HARRIS Hotel Sentraland, Semarang"
                />
                <WeddingInfoBox
                  title="Syukuran Pernikahan"
                  time="Sesuai jadwal undangan di e-ticket "
                  date="Monday, 17 June 2024"
                  description="Orange Ballroom <br/>HARRIS Hotel Sentraland, Semarang"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

WeddingSection.propTypes = {
  isInvitation: bool.isRequired,
};

export default React.memo(WeddingSection);
